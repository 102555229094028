<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-2">
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("transportationFee.serviceType")
                    }}</label>
                    <a-select
                        v-model="model.service_type_id"
                        class="tw-w-full"
                        :placeholder="$t('transportationFee.serviceType')"
                        :options="serviceTypes"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('service_type_id')"
                    >
                        {{ errors.first("service_type_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("transportationFee.distantFrom")
                    }}</label>
                    <input
                        v-model.number="model.distance_km_from"
                        type="number"
                        step="any"
                        class="form-control"
                        :placeholder="$t('transportationFee.distantFrom')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('distance_km_from')"
                    >
                        {{ errors.first("distance_km_from") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("transportationFee.distantTo")
                    }}</label>
                    <input
                        v-model.number="model.distance_km_to"
                        type="number"
                        step="any"
                        class="form-control"
                        :placeholder="$t('transportationFee.distantTo')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('distance_km_to')"
                    >
                        {{ errors.first("distance_km_to") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("transportationFee.currency")
                    }}</label>
                    <a-select
                        v-model.number="model.currency_id"
                        :options="currencies"
                        class="tw-w-full"
                        :placeholder="$t('transportationFee.currency')"
                    ></a-select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("transportationFee.amount")
                    }}</label>
                    <input
                        v-model.number="model.fee_amount"
                        type="number"
                        class="form-control"
                        step="any"
                        :placeholder="$t('transportationFee.amount')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('fee_amount')"
                    >
                        {{ errors.first("fee_amount") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("transportationFee.chargeType")
                    }}</label>
										<select 
											v-model="model.charge_type"
											class="form-control"
										>
											<option value="Fixed">Fixed</option>
											<option value="Per Km">Per Km</option>
										</select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('charge_type')"
                    >
                        {{ errors.first("charge_type") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button
                        @click.prevent="$emit('cancel')"
                        :disabled="loading"
                        >{{ $t("cancel") }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        outline
                        @click.prevent="onSaveAddNew"
                        :disabled="loading"
                        >{{ $t("saveAddNew") }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        @click.prevent="onSave"
                        :disabled="loading"
                        >{{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        :disabled="loading"
                        @click.prevent="onUpdate"
                        >{{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { isEmpty } from "lodash";
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";

export default {
    name: "transportationFee",
    data() {
        return {
            loading: false,
            errors: new Errors(),
            model: {
                fee_id: 0,
                service_type_id: undefined,
                distance_km_from: "",
                distance_km_to: "",
                currency_id: undefined,
                fee_amount: "",
								charge_type: 'Fixed'
            }
        };
    },
    computed: {
        ...mapState("finance/transportationFee", ["edit_data", "formModels"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        serviceTypes() {
            if (!this.formModels.serviceType) return [];

            return this.formModels.serviceType.map(el => ({
                value: el.service_type_id,
                label: el.service_type_name_en
            }));
        },
        currencies() {
            if (!this.formModels.currency) return [];
            return this.formModels.currency.map(el => ({
                value: el.currency_id,
                label: el.currency_code
            }));
        },
        defaultCurrency() {
            if (!this.formModels.currency) return "";
            return this.formModels.currency.find(el => el.is_default);
        }
    },
    created() {
        this.setEditData();
    },
    methods: {
        ...mapActions("finance/transportationFee", [
            "getFormViewData",
            "store",
            "update"
        ]),
        fetchFormView({ params, index }) {
            this.loading = true;
            this.getFormViewData({ params: params, index: index })
                .then(() => {
                    this.model.currency_id = this.defaultCurrency.currency_id;
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSave() {
            this.loading = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("fetchData");
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSaveAddNew() {
            this.loading = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onUpdate() {
            this.loading = true;
            this.update({
                id: this.model.fee_id,
                data: this.model
            })
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearInput() {
            this.model.fee_id = 0;
            this.model.service_type_id = undefined;
            this.model.distance_km_from = "";
            this.model.distance_km_to = "";
            this.model.currency_id = undefined;
            this.model.fee_amount = "";
            this.model.charge_type = "Fixed";
        },
        async setEditData() {
            this.loading = true;
            await this.getFormViewData({ params: {}, index: undefined });
            if (!isEmpty(this.edit_data)) {
                this.model.fee_id = this.edit_data.fee_id;
                this.model.service_type_id = this.edit_data.service_type_id;
                this.model.distance_km_from = this.edit_data.distance_km_from;
                this.model.distance_km_to = this.edit_data.distance_km_to;
                this.model.currency_id = this.edit_data.currency_id;
                this.model.fee_amount = this.edit_data.fee_amount;
                this.model.charge_type = this.edit_data.charge_type;
            }
            this.loading = false;
        }
    }
};
</script>
